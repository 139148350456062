import {
  AccountProfile,
  AccountInfo,
  Username,
  PasswordInfo,
  AuthTokenCredentials,
  CompanyName,
  CompanyWebsite,
  FriendlyName,
} from '../types/user';
import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { RouteHelpers, Routes } from './routes';
import { getCheckUsernameExistsRefinedErrorMessage } from './errors';
import USERNAMEERRORS from '../constants/usernameErrors';
import {
  AccountProfileDto,
  EmailDto,
  PasswordDto,
  AuthTokenCredentialsDto,
} from '../dtos';
import { SSOTeammateDto } from 'src/pages/Teammates/features/scopeSelector/types';
import { TeammatesRoutes } from 'src/pages/Teammates/apiRoutes';

export function updateUserProfile(
  accountProfile: AccountProfile
): Promise<AccountProfileDto | AccountInfo> {
  const { firstName, lastName, phone } = accountProfile;
  const updatedProfile = {
    first_name: firstName,
    last_name: lastName,
    phone,
  };

  return AuthedHttp.patch<AccountProfileDto>(Routes.UserProfile, updatedProfile)
    .then((response: ResponseBodySuccess<AccountProfileDto>) => response.json())
    .catch((err) => {
      return {
        errors: [
          {
            field: 'account_info',
            message:
              'An error occurred attempting to update your account info. Please try again.',
          },
        ],
      };
    });
}

export function updateEmail(accountProfile: AccountProfile): Promise<EmailDto> {
  const { email = '' } = accountProfile;
  const updatedEmail = { email };

  return AuthedHttp.put<EmailDto>(Routes.UserEmail, updatedEmail)
    .then((response: ResponseBodySuccess<EmailDto>) => response.json())
    .catch((err) => {
      return {
        errors: [
          {
            field: 'email',
            message:
              'An error occurred attempting to update your email. Please try again.',
          },
        ],
      };
    });
}

export function getUsername(): Promise<Username> {
  return AuthedHttp.get<Username>(Routes.Username)
    .then((response: ResponseBodySuccess<Username>) => {
      return response.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'username',
            message:
              'An error occurred attempting to retrieve your username. Please try again.',
          },
        ],
      };
    });
}

export function attemptUpdateUsername(username: string): Promise<Username> {
  const requestBody = {
    username,
  };

  return AuthedHttp.put<Username>(Routes.Username, requestBody)
    .then((response: ResponseBodySuccess<Username>) => {
      return response.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'username',
            message:
              'An error occurred attempting to update your username. Please try again.',
          },
        ],
      };
    });
}

export function checkUsernameExists(username = ''): Promise<Username | void> {
  return AuthedHttp.get<Username>(RouteHelpers.CheckUsernameExists(username))
    .then(async (response: ResponseBodySuccess<Username>) => {
      let errorMessage = '';
      switch (response.status) {
        case 404:
          // username available
          return { username };
        case 429:
          errorMessage = USERNAMEERRORS.USERNAME_TOO_MANY_REQUESTS;
          break;
        case 204:
          errorMessage = USERNAMEERRORS.USERNAME_TAKEN;
          break;
        case 400:
          await response.json().then((data: Username) => {
            const serverErrorMessage = data.errors![0].message;
            errorMessage = getCheckUsernameExistsRefinedErrorMessage(
              serverErrorMessage
            );

            return errorMessage;
          });
          break;
        default:
          errorMessage = USERNAMEERRORS.USERNAME_DEFAULT;
      }
      return {
        errors: [{ field: 'username', message: errorMessage }],
      };
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'username',
            message:
              'An error occurred attempting to update your username. Please try again.',
          },
        ],
      };
    });
}

export function updatePassword(
  passwordInfo: PasswordInfo
): Promise<Pick<PasswordDto, 'errors'>> {
  const {
    currentPassword: old_password,
    newPassword: new_password,
  } = passwordInfo;
  const updatedPassword = {
    old_password,
    new_password,
  } as PasswordDto;

  return AuthedHttp.put<PasswordDto>(Routes.UserPassword, updatedPassword)
    .then((response: ResponseBodySuccess<PasswordDto>) => response.json())
    .catch((error) => {
      if (error.errors) {
        return error;
      }
      return {
        errors: [
          {
            field: 'password',
            message:
              'An error occurred attempting to update your password. Please try again.',
          },
        ],
      };
    });
}

export function updatePasswordAuthToken(
  authTokenCredentials: AuthTokenCredentials
): Promise<AuthTokenCredentialsDto> {
  return AuthedHttp.post<AuthTokenCredentialsDto>(
    Routes.PublicTokens,
    authTokenCredentials
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw new Error('Failed to update mako_auth_token!');
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'mako_auth_token',
            message:
              'Password change was successful but unable to refresh current session.',
          },
        ],
      };
    });
}

export function updateCompanyWebsite(
  accountProfile: AccountProfile
): Promise<CompanyWebsite | void> {
  const requestBody = {
    website: accountProfile.website,
  };

  return AuthedHttp.put<CompanyWebsite>(Routes.UserProfile, requestBody)
    .then((response: ResponseBodySuccess<CompanyWebsite>) => {
      return response.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'companyWebsite',
            message:
              'An error occurred attempting to update your company website. Please try again.',
          },
        ],
      };
    });
}

export function updateAddress(
  accountProfile: AccountProfile
): Promise<AccountProfileDto | void> {
  const requestBody = {
    address: accountProfile.address,
    address2: accountProfile.address2,
    city: accountProfile.city,
    country: accountProfile.country,
    state: accountProfile.state,
    zip: accountProfile.zip,
  };

  return AuthedHttp.patch<AccountProfileDto>(Routes.UserProfile, requestBody)
    .then((response: ResponseBodySuccess<AccountProfileDto>) => {
      return response.json();
    })
    .catch((err) => {
      const errors = {
        errors: [
          {
            field: 'address',
            message:
              'An error occurred attempting to update your address. Please try again.',
          },
        ],
      } as AccountProfileDto;
      return errors;
    });
}

export function updateCompanyName(
  accountProfile: AccountProfile
): Promise<CompanyName | void> {
  const requestBody = {
    company: accountProfile.company,
  };
  return AuthedHttp.put<CompanyName>(Routes.UserProfile, requestBody)
    .then((response: ResponseBodySuccess<CompanyName>) => {
      return response.json();
    })
    .catch((err) => {
      return {
        errors: [
          {
            field: 'company',
            message:
              'An error occurred attempting to update your company name. Please try again.',
          },
        ],
      };
    });
}

export function updateFriendlyName(
  accountProfile: AccountProfile
): Promise<FriendlyName | void> {
  const requestBody = {
    friendly_name: accountProfile.friendlyName,
  };

  return AuthedHttp.post<FriendlyName>(Routes.AccountProfile, requestBody)
    .then((response: ResponseBodySuccess<any>) => {
      const statusCode = response.status;
      if (response.ok) {
        const noContent = {};
        return Promise.resolve({ statusCode, data: noContent });
      }
      return response.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'friendly_name',
            message:
              'An error occurred attempting to update your friendly name. Please try again.',
          },
        ],
      } as FriendlyName;
    });
}

export async function fetchTeammate(
  accountProfile: AccountProfile
): Promise<boolean> {
  const teammateResp = await AuthedHttp.get<SSOTeammateDto>(
    TeammatesRoutes.getTeammate({ username: accountProfile.username! })
  );

  if (!teammateResp.ok) {
    return false;
  }

  const teammateInfo = await teammateResp.json();
  return teammateInfo.is_admin;
}
