const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const {
  showAccountFriendlyName,
} = require("src/helpers/featureFlags/showAccountFriendlyName");

module.exports = Backbone.Model.extend({
  url: showAccountFriendlyName()
    ? `${APIConfig.host}account/profile_v2`
    : `${APIConfig.host}account/profile`,
});
