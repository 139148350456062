import { ActionTypes } from './actionTypes';

import {
  AccountProfile,
  AccountInfo,
  Username,
  PasswordInfo,
  AuthTokenCredentials,
  CompanyName,
  CompanyWebsite,
  FriendlyName,
} from '../types/user';
import { ActionsUnion, createAction } from '../actionBuilder';
import { AccountProfileDto, EmailDto, PasswordDto } from '../dtos';

export const createUserProfile = (payload: AccountProfileDto) => {
  return { type: ActionTypes.CreateUserProfile, payload };
};

// Only add actions to this if they use epics
export const Actions = {
  updateUserProfile: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateUserProfileRequest, accountProfile),
  updateUserProfileSuccess: (accountProfile: AccountProfileDto) =>
    createAction(ActionTypes.UpdateUserProfileSuccess, accountProfile),
  updateUserProfileFailure: (errors: AccountInfo) =>
    createAction(ActionTypes.UpdateUserProfileFailure, errors),
  clearAccountInfoServerErrors: () =>
    createAction(ActionTypes.ClearAccountInfoServerErrors),

  updateEmailRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateEmailRequest, accountProfile),
  updateEmailSuccess: (email: EmailDto) =>
    createAction(ActionTypes.UpdateEmailSuccess, email),
  updateEmailFailure: (errors: EmailDto) =>
    createAction(ActionTypes.UpdateEmailFailure, errors),

  openEmailConfirmationModal: () =>
    createAction(ActionTypes.OpenEmailConfirmationModal),
  closeEmailConfirmationModal: () =>
    createAction(ActionTypes.CloseEmailConfirmationModal),
  allowResendConfirmation: () =>
    createAction(ActionTypes.AllowResendConfirmation),
  clearEmailServerErrors: () =>
    createAction(ActionTypes.ClearEmailServerErrors),

  updateUsernameRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateUsernameRequest, accountProfile),
  updateUsernameSuccess: (username: Username) =>
    createAction(ActionTypes.UpdateUsernameSuccess, username),
  updateUsernameFailure: (errors: Username) =>
    createAction(ActionTypes.UpdateUsernameFailure, errors),
  clearUsernameServerErrors: () =>
    createAction(ActionTypes.ClearUsernameServerErrors),

  updateFriendlyNameRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateFriendlyNameRequest, accountProfile),
  updateFriendlyNameSuccess: () =>
    createAction(ActionTypes.UpdateFriendlyNameSuccess, {}),
  updateFriendlyNameFailure: (errors: FriendlyName) =>
    createAction(ActionTypes.UpdateFriendlyNameFailure, errors),
  clearFriendlyNameServerErrors: () =>
    createAction(ActionTypes.ClearFriendlyNameServerErrors),

  attemptUpdateUsername: (username: Username) => {
    return createAction(ActionTypes.AttemptUpdateUsername, username);
  },

  updatePasswordRequest: (passwordInfo: PasswordInfo) =>
    createAction(ActionTypes.UpdatePasswordRequest, passwordInfo),
  updatePasswordAuthToken: (authTokenCredentials: AuthTokenCredentials) =>
    createAction(ActionTypes.UpdatePasswordAuthToken, authTokenCredentials),
  updatePasswordSuccess: () =>
    createAction(ActionTypes.UpdatePasswordSuccess, {}),
  updatePasswordFailure: (errors: Pick<PasswordDto, 'errors'>) =>
    createAction(ActionTypes.UpdatePasswordFailure, errors),
  clearPasswordServerErrors: () =>
    createAction(ActionTypes.ClearPasswordServerErrors),

  updateCompanyNameRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateCompanyNameRequest, accountProfile),
  updateCompanyNameSuccess: (companyName: CompanyName) =>
    createAction(ActionTypes.UpdateCompanyNameSuccess, companyName),
  updateCompanyNameFailure: (errors: CompanyName) =>
    createAction(ActionTypes.UpdateCompanyNameFailure, errors),
  clearCompanyNameServerErrors: () =>
    createAction(ActionTypes.ClearCompanyNameServerErrors),

  updateCompanyWebsiteRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateCompanyWebsiteRequest, accountProfile),
  updateCompanyWebsiteSuccess: (companyWebsite: CompanyWebsite) =>
    createAction(ActionTypes.UpdateCompanyWebsiteSuccess, companyWebsite),
  updateCompanyWebsiteFailure: (errors: CompanyWebsite) =>
    createAction(ActionTypes.UpdateCompanyWebsiteFailure, errors),
  clearCompanyWebsiteServerErrors: () =>
    createAction(ActionTypes.ClearCompanyWebsiteServerErrors),

  updateAddressRequest: (accountProfile: AccountProfile) =>
    createAction(ActionTypes.UpdateAddressRequest, accountProfile),
  updateAddressSuccess: (address: AccountProfileDto) =>
    createAction(ActionTypes.UpdateAddressSuccess, address),
  updateAddressFailure: (errors: AccountProfileDto) =>
    createAction(ActionTypes.UpdateAddressFailure, errors),
  clearAddressServerErrors: () =>
    createAction(ActionTypes.ClearAddressServerErrors),
  getUserType: () => createAction(ActionTypes.GetUserType),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
