import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../types/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import {
  GenericNetworkSlice,
  NetworkState,
  Unrequested,
} from './sliceNetworkTypes';
import { sanitizer } from '../dtoSanitizer';

const GenericFetchError = 'Network Error. Failed to fetch account profile';

export interface AccountProfileDto {
  address: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  state: string;
  username: string;
  website: string;
  zip: number;
  friendly_name: string;
}

export interface AccountProfile {
  address: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  username: string;
  website: string;
  zip: number;
  friendlyName: string;
}

export const getAccountProfile = createAsyncThunk<
  AccountProfile,
  ResponseBody<AccountProfileDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'accountProfile/fetchAccountProfile',
  async (accountProfilePromise, thunkApi) => {
    try {
      const response = await accountProfilePromise;

      if (response.ok) {
        const data = await response.json();
        return accountProfileAdapter(data);
      }

      const errorResponse = await response.json();

      throw new Error(errorResponse.errors[0].message);
    } catch {
      return thunkApi.rejectWithValue({
        message: GenericFetchError,
      } as ResponseError);
    }
  }
);

const accountProfileAdapter = (dto: AccountProfileDto): AccountProfile => {
  const sanitizedAccountProfile = sanitizer(dto);
  const {
    first_name,
    last_name,
    friendly_name,
    ...sanitized
  } = sanitizedAccountProfile;

  return {
    firstName: first_name,
    lastName: last_name,
    friendlyName: friendly_name,
    ...sanitized,
  } as AccountProfile;
};

export type AccountProfileState = GenericNetworkSlice<AccountProfile>;

const initialState: Unrequested = {
  networkState: NetworkState.Unrequested,
  data: null,
};

export const accountProfileSlice = createSlice<AccountProfileState, any>({
  name: 'accountProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountProfile.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getAccountProfile.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = {
          ...action.payload,
        };
      }
    });
    builder.addCase(getAccountProfile.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});
