export enum Routes {
  UserProfile = 'user/profile',
  UserEmail = 'user/email',
  Username = 'user/username',
  UserPassword = 'user/password',
  PublicTokens = 'public/tokens',
  AccountProfile = 'account/profile_v2',
}

export const RouteHelpers = {
  CheckUsernameExists: (username = ''): string =>
    `public/signup/username/${username}`,
};
