export enum ActionTypes {
  CreateUser = 'createUser',
  CreateUserProfile = 'createUserProfile',

  UpdateUserProfile = 'updateUserProfile',
  UpdateUserProfileRequest = 'updateUserProfile.request',
  UpdateUserProfileSuccess = 'updateUserProfile.success',
  UpdateUserProfileFailure = 'updateUserProfile.failure',

  ClearAccountInfoServerErrors = 'clearAccountInfoServerErrors',

  UpdateEmail = 'updateEmail',
  UpdateEmailRequest = 'updateEmail.request',
  UpdateEmailSuccess = 'updateEmail.success',
  UpdateEmailFailure = 'updateEmail.failure',

  OpenEmailConfirmationModal = 'openEmailConfirmationModal',
  CloseEmailConfirmationModal = 'closeEmailConfirmationModal',
  AllowResendConfirmation = 'allowResendConfirmation',
  ClearEmailServerErrors = 'clearEmailServerErrors',

  UpdateUsername = 'updateUsername',
  UpdateUsernameRequest = 'updateUsername.request',
  UpdateUsernameSuccess = 'updateUsername.success',
  UpdateUsernameFailure = 'updateUsername.failure',

  UpdateFriendlyName = 'updateFriendlyName',
  UpdateFriendlyNameRequest = 'updateFriendlyName.request',
  UpdateFriendlyNameSuccess = 'updateFriendlyName.success',
  UpdateFriendlyNameFailure = 'updateFriendlyName.failure',

  AttemptUpdateUsername = 'attemptUpdateUsername',

  ClearUsernameServerErrors = 'clearUsernameServerErrors',
  ClearFriendlyNameServerErrors = 'clearFriendlyNameServerErrors',

  UpdatePassword = 'updatePassword',
  UpdatePasswordRequest = 'updatePassword.request',
  UpdatePasswordAuthToken = 'updatePasswordAuthToken',
  UpdatePasswordSuccess = 'updatePassword.success',
  UpdatePasswordFailure = 'updatePassword.failure',

  ClearPasswordServerErrors = 'clearPasswordServerErrors',

  UpdateCompanyName = 'updateCompanyName',
  UpdateCompanyNameRequest = 'updateCompanyName.request',
  UpdateCompanyNameSuccess = 'updateCompanyName.success',
  UpdateCompanyNameFailure = 'updateCompanyName.failure',

  ClearCompanyNameServerErrors = 'clearCompanyNameServerErrors',

  UpdateCompanyWebsite = 'updateCompanyWebsite',
  UpdateCompanyWebsiteRequest = 'updateCompanyWebsite.request',
  UpdateCompanyWebsiteSuccess = 'updateCompanyWebsite.success',
  UpdateCompanyWebsiteFailure = 'updateCompanyWebsite.failure',

  ClearCompanyWebsiteServerErrors = 'clearCompanyWebsiteServerErrors',

  UpdateAddress = 'updateAddress',
  UpdateAddressRequest = 'updateAddress.request',
  UpdateAddressSuccess = 'updateAddress.success',
  UpdateAddressFailure = 'updateAddress.failure',

  ClearAddressServerErrors = 'clearAddressServerErrors',

  GetUserType = 'userType.request',
}
