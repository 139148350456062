import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import * as UserActions from './actions';
import { ActionTypes } from './actionTypes';
import {
  updateEmail,
  updateUserProfile,
  checkUsernameExists,
  attemptUpdateUsername,
  updatePassword,
  updatePasswordAuthToken,
  updateCompanyName,
  updateCompanyWebsite,
  updateAddress,
  updateFriendlyName,
} from './service';
import {
  AccountInfo,
  Username,
  CompanyName,
  CompanyWebsite,
  AuthTokenCredentials,
  FriendlyName,
} from '../types/user';
import {
  AccountProfileDto,
  EmailDto,
  PasswordDto,
  AuthTokenCredentialsDto,
} from '../dtos';
import { updateMakoAuthCookieHeader } from '../../helpers/default_json_headers';

const updateUserEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateUserProfile>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateUserProfileSuccess>
  | ReturnType<typeof UserActions.Actions.updateUserProfileFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateUserProfileRequest),
    switchMap((action) => {
      return from(updateUserProfile(action.payload));
    }),
    map((data: AccountProfileDto | AccountInfo) => {
      if (data.errors) {
        return UserActions.Actions.updateUserProfileFailure(
          data as AccountInfo
        );
      }
      return UserActions.Actions.updateUserProfileSuccess(
        data as AccountProfileDto
      );
    })
  );
};

const updateEmailEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateEmailRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateEmailSuccess>
  | ReturnType<typeof UserActions.Actions.updateEmailFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateEmailRequest),
    switchMap((action) => {
      return from(updateEmail(action.payload));
    }),
    map((data: EmailDto) => {
      if (data.errors) {
        return UserActions.Actions.updateEmailFailure(data);
      }
      return UserActions.Actions.updateEmailSuccess(data);
    })
  );
};

const updateUsernameEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateUsernameRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateUsernameFailure>
  | ReturnType<typeof UserActions.Actions.attemptUpdateUsername>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateUsernameRequest),
    switchMap((action) => {
      return from(checkUsernameExists(action.payload.username));
    }),
    map((data: Username) => {
      if (data.errors) {
        return UserActions.Actions.updateUsernameFailure(data);
      }
      return UserActions.Actions.attemptUpdateUsername(data);
    })
  );
};

const attemptUpdateUsernameEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.attemptUpdateUsername>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateUsernameSuccess>
  | ReturnType<typeof UserActions.Actions.updateUsernameFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.AttemptUpdateUsername),
    switchMap((action) => {
      const { username } = action.payload;
      return from(attemptUpdateUsername(username || ''));
    }),
    map((data) => {
      if (data.errors) {
        return UserActions.Actions.updateUsernameFailure(data);
      }
      return UserActions.Actions.updateUsernameSuccess(data);
    })
  );
};

const updateFriendlyNameEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateFriendlyNameRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateFriendlyNameSuccess>
  | ReturnType<typeof UserActions.Actions.updateFriendlyNameFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateFriendlyNameRequest),
    switchMap((action) => {
      return from(updateFriendlyName(action.payload));
    }),
    map((data: FriendlyName) => {
      if (data.errors) {
        return UserActions.Actions.updateFriendlyNameFailure(data);
      }
      return UserActions.Actions.updateFriendlyNameSuccess();
    })
  );
};

const updatePasswordEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updatePasswordRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updatePasswordAuthToken>
  | ReturnType<typeof UserActions.Actions.updatePasswordFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdatePasswordRequest),
    switchMap((action) => {
      return from(updatePassword(action.payload)).pipe(
        map((data: Pick<PasswordDto, 'errors'>) => {
          // Password endpoint either returns no response body on success
          // or an errors object upon failure
          if ((data as Pick<PasswordDto, 'errors'>).errors) {
            return UserActions.Actions.updatePasswordFailure(
              data as Pick<PasswordDto, 'errors'>
            );
          }

          const { username, newPassword } = action.payload;
          const authTokenCredentials = {
            username,
            password: newPassword,
          } as AuthTokenCredentials;

          return UserActions.Actions.updatePasswordAuthToken(
            authTokenCredentials
          );
        })
      );
    })
  );
};

const updatePasswordAuthTokenEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updatePasswordAuthToken>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updatePasswordSuccess>
  | ReturnType<typeof UserActions.Actions.updatePasswordFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdatePasswordAuthToken),
    switchMap((action) => {
      return from(updatePasswordAuthToken(action.payload));
    }),
    map((data: AuthTokenCredentialsDto) => {
      if (data.errors) {
        return UserActions.Actions.updatePasswordFailure(data);
      }

      // Reset auth token to prevent 401s on subsequent makoapi requests
      (window as any).$.cookie('mako_auth_token', data.token, { secure: true });
      // Once we reset the auth token, we need to update our fetches with the
      // new mako_auth_token in the Authorization header
      updateMakoAuthCookieHeader();

      return UserActions.Actions.updatePasswordSuccess();
    })
  );
};

const updateCompanyNameEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateCompanyNameRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateCompanyNameSuccess>
  | ReturnType<typeof UserActions.Actions.updateCompanyNameFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateCompanyNameRequest),
    switchMap((action) => {
      return from(updateCompanyName(action.payload));
    }),
    map((data: CompanyName) => {
      if (data.errors) {
        return UserActions.Actions.updateCompanyNameFailure(data);
      }
      return UserActions.Actions.updateCompanyNameSuccess(data);
    })
  );
};

const updateAddressEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateAddressRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateAddressSuccess>
  | ReturnType<typeof UserActions.Actions.updateAddressFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateAddressRequest),
    switchMap((action) => {
      return from(updateAddress(action.payload));
    }),
    map((data: AccountProfileDto) => {
      if (data.errors) {
        return UserActions.Actions.updateAddressFailure(data);
      }
      return UserActions.Actions.updateAddressSuccess(data);
    })
  );
};

const updateCompanyWebsiteEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.updateCompanyWebsiteRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.updateCompanyWebsiteSuccess>
  | ReturnType<typeof UserActions.Actions.updateCompanyWebsiteFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateCompanyWebsiteRequest),
    switchMap((action) => {
      return from(updateCompanyWebsite(action.payload));
    }),
    map((data: CompanyWebsite) => {
      if (data.errors) {
        return UserActions.Actions.updateCompanyWebsiteFailure(data);
      }
      return UserActions.Actions.updateCompanyWebsiteSuccess(data);
    })
  );
};

export default [
  updateUserEpic,
  updateEmailEpic,
  updateUsernameEpic,
  attemptUpdateUsernameEpic,
  updatePasswordEpic,
  updatePasswordAuthTokenEpic,
  updateCompanyNameEpic,
  updateCompanyWebsiteEpic,
  updateAddressEpic,
  updateFriendlyNameEpic,
];
